<template>
  <div class="lookbook-product-group">
    <LookbookImageWrapper
      v-if="item.preview_photo"
      v-slot="image"
      :products="item.products"
      :lookbook-slug="item.code"
    >
      <CustomImage
        class="product-group-detail__image"
        :image-id="item.preview_photo.id"
        :alt="item.name"
        aspect-ratio="48.14%"
        @load="image.onLoaded"
      />
    </LookbookImageWrapper>
    <ul v-if="item.count_products" class="product-group__products">
      <li v-for="product in products" :key="product.id" class="product-group__products-item">
        <component
          :is="getTagProduct(product)"
          v-bind="getPropsProduct(product)"
          class="product-group__products-link product-group__preview bg-image"
        >
          <CustomImage
            class="product-group__preview-image"
            :image-id="product.image.id"
            :width="imageSizes[0]"
            :height="imageSizes[1]"
            :alt="product.name"
          />
          <div v-if="product.price" class="product-group__preview-price font font_m font_bold">
            {{ getCurrency(product.price) }}
            <div v-if="visibleOldPrice(product)" class="product-group__preview-price-old font_grey font_sm">
              {{ getCurrency(product.cost) }}
            </div>
          </div>
        </component>
      </li>
    </ul>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import LookbookImageWrapper from '@/components/catalog/lookbook/Image/Wrapper';

import './index.css'
import CustomImage from '~/components/elements/CustomImage';

export default {
  name: 'LookbookComponent',
  components: {
    CustomImage,
    LookbookImageWrapper,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    bannerImageSizes: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters('currency', { getCurrency: 'getCurrency' }),
    products() {
      return this.item?.products?.slice(0, 8) || [];
    },
    mqIsMobile() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile' || this.$mq === 'tablet';
    },
    imageSizes() {
      return this.mqIsMobile ? [96, 96] : [122, 122];
    },
  },
  methods: {
    getTagProduct(product) {
      const categoryLink = this.getCategoryLink(product.categoryCodes);
      return categoryLink ? 'nuxt-link' : 'div';
    },
    getCategoryLink(categoryCodes) {
      return categoryCodes && categoryCodes.length > 0 && categoryCodes[categoryCodes.length - 1];
    },
    getPropsProduct(product) {
      const categoryLink = this.getCategoryLink(product.categoryCodes);
      if (categoryLink) {
        return {
          to: {
            name: 'catalog.category.product',
            params: {
              category: categoryLink,
              product: product.code,
            },
          },
        };
      }
      return false;
    },
    visibleOldPrice(product) {
      const oldPrice = parseFloat(product.cost);
      const newPrice = parseFloat(product.price);
      return oldPrice && oldPrice !== newPrice;
    },
  },
};
</script>
