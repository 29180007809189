<template>
  <div ref="lookbookPointWrapper" class="lookbook-points">
    <template v-if="isLoadedImage">
      <LookbookImagePoint
        v-for="(product, idx) in products"
        :key="`${screenWidth}-${product.id}-${idx}`"
        :lookbook-slug="lookbookSlug"
        :product="product"
        :width-wrapper="widthWrapper"
        :height-wrapper="heightWrapper"
      />
    </template>
    <slot :on-loaded="onLoadedImage"></slot>
  </div>
</template>

<script>
import LookbookImagePoint from '@/components/catalog/lookbook/Image/Point';

export default {
  name: 'LookbookImageWrapper',
  components: {
    LookbookImagePoint,
  },
  props: {
    lookbookSlug: {
      type: String,
      required: true,
    },
    products: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isLoadedImage: false,
      screenWidth: null,
      widthWrapper: 0,
      heightWrapper: 0,
      currentActiveProductId: null,
    };
  },
  watch: {
    screenWidth() {
      this.calculateWrapperSize()
    },
  },
  mounted() {
    this.getCurrentScreenWidth();
    window.addEventListener('resize', this.getCurrentScreenWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getCurrentScreenWidth);
  },
  methods: {
    onLoadedImage() {
      this.isLoadedImage = true;
      this.calculateWrapperSize()
    },
    getCurrentScreenWidth() {
      this.screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    },
    calculateWrapperSize() {
      this.heightWrapper = this.$refs.lookbookPointWrapper.clientHeight;
      this.widthWrapper = this.$refs.lookbookPointWrapper.clientWidth;
    },
  },
};
</script>
