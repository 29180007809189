<template>
  <ul class="product-group-info">
    <li v-if="count" class="product-group-info__item product-group-info__item--products">
      {{ phraseDeclension(count, productTextVariants) }}
    </li>
  </ul>
</template>

<script>
import './index.css';
import { phraseDeclension } from '@/plugins/formatting';

export default {
  name: 'ProductGroupInfo',
  props: {
    count: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      productTextVariants: ['товар', 'товаров', 'товара'],
    }
  },
  methods: {
    phraseDeclension,
  },
}
</script>
