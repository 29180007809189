var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lookbook-points__item"},[_c('a',{key:_vm.product.id,staticClass:"lookbook-points__target",style:(`left: ${_vm.pointXCoordInPixels}px; top: ${_vm.pointYCoordInPixels}px`),attrs:{"id":`${_vm.lookbookSlug}__product-${_vm.product.id}`,"aria-label":"Показать товар","href":`${_vm.lookbookSlug}__product-${_vm.product.id}`},on:{"click":function($event){$event.preventDefault();},"mouseover":(event) => event.target.focus()}}),_vm._v(" "),_c('div',{staticClass:"lookbook-points-tooltip",style:(`left: ${_vm.calculatedTooltipXCoordInPixels}px; top: ${_vm.calculatedTooltipYCoordInPixels}px`)},[_c('div',{staticClass:"lookbook-points-tooltip__image-wrapper"},[_c('CustomImage',{staticClass:"lookbook-points-tooltip__image",attrs:{"image-id":_vm.product.image.id,"width":_vm.imageSizes[0],"height":_vm.imageSizes[1],"alt":_vm.product.name}})],1),_vm._v(" "),_c('div',{staticClass:"lookbook-points-tooltip__body"},[_c('span',{staticClass:"lookbook-points-tooltip__title"},[_vm._v(_vm._s(_vm.shortName))]),_vm._v(" "),_c('div',{staticClass:"lookbook-points-tooltip__footer"},[_c('span',{staticClass:"lookbook-points-tooltip__price"},[_vm._v(_vm._s(_vm.formattedPrice))]),_vm._v(" "),_c('nuxt-link',{staticClass:"lookbook-points-tooltip__link",attrs:{"to":{
            name: 'catalog.category.product',
            params: {
              category: _vm.getCategoryLink(_vm.product.categoryCodes),
              product: _vm.product.code,
            },
          }}},[_c('svg-icon',{attrs:{"name":"arrow-right","width":"16","height":"14"}})],1)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }